@import url('https://fonts.googleapis.com/css2? Family= Roboto:wght@100;300;400;500;700 & display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f5;
    border-radius: 9px;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 9px;
    background-color: rgba(161, 161, 170, 0.7);
}
